@import '../../assets/sass/devices';

.AppHeader {
    background-color: var(--secondary);
    color: var(--white);
    font-weight: bold;
    padding: 0.8em 0 0.5em 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
        width: 40%;

        @include tablet {
            width: 18%;
        }

        @include desktop {
            width: 10%;
        }
    }

    .feedback {
        &__button {
            background-color: var(--secondary) !important;
        }
        &__input {
            padding: 0 1em 1em 1em;
        }
        &__icon {
            width: 100% !important;
            color: var(--white);
        }
    }
}
