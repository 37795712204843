@import 'assets/sass';

body {
    @include fontsize($fontsize-normal);

    background-color: var(--layout);
    font-family: $default-font;
    -moz-osx-font-smoothing: grayscale;
    // prettier-ignore
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
}

.Main {
    min-height: 90vh;
}
