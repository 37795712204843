footer {
    background-color: var(--secondary);
    display: flex;
    justify-content: center;
    padding: 1em 0;

    p {
        color: var(--white);
        font-weight: 600;
        font-size: 12px;
    }

    img {
        margin-left: 0.5em;
    }

    a {
        border-bottom: none;
    }
}
